





































import { Vue, Prop, Component } from 'vue-property-decorator';
import { Promotion } from '@/model/promotionResult';
@Component
export default class EdPromotion extends Vue {
  private promotionList: Promotion[] = [];
  private currentPrmotion: number = 0;
  @Prop({ default: 5000 }) delay!: number;
  created () {
    this.$Api.promotion.getPromotionListV2('catalogsPromotion').then(result => {
      this.promotionList = result.Promotion;
      this.currentPrmotion = 0;
    });
    setInterval(() => { this.currentPrmotion = (this.currentPrmotion + 1) % this.promotionList.length; }, this.delay);
  }
  choosePromotion (e) {
    let target = e.target as HTMLElement;
    if (target.nodeName === 'IMG') {
      this.currentPrmotion = Number(target.dataset.key as string);
    }
  }
  enter () {
    console.log('aa');
  }
  toCatalogs (item) {
    this.$router.push(item.BannerList[0].Url + '?name=' + item.Name);
  }
}
