import { render, staticRenderFns } from "./EdCatalogsPromotion.vue?vue&type=template&id=49dc562c&scoped=true&"
import script from "./EdCatalogsPromotion.vue?vue&type=script&lang=ts&"
export * from "./EdCatalogsPromotion.vue?vue&type=script&lang=ts&"
import style0 from "./EdCatalogsPromotion.vue?vue&type=style&index=0&id=49dc562c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49dc562c",
  null
  
)

export default component.exports